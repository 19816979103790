import React from 'react'
import Footer from '../presentation/footer'
import Navigation from '../presentation/navigation'

export default function Page({children}) {
    return (
        <div className="container">
            <section>
                <Navigation />
            </section>
            <section>
                {children}
            </section>
            <section>
                <Footer />
            </section>
        </div>
    )
}
