import { Link } from 'gatsby';
import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
        <footer>
            <div className="vh-25 p-20 d-grid grid-cols-3" style={{background:'black'}}>
                <div className="text-white">
                    <p className="h3">Site Map</p>
                    <p><Link href="/">Home</Link></p>
                    <p><Link href="/films">Films</Link></p>
                    <p><Link href="/stills">Stills</Link></p>
                    <p><Link href="/hire">Hire Me</Link></p>
                </div>
                <div className="text-white">
                </div>
                <div className="text-white text-right">
                    <p className="h3">Contact Me</p>
                    <p><a href="tel:+447477118727">(+44) 07477 118 727</a></p>
                    <p><a href="mailto:josh@carroll.digital">josh@carroll.digital</a></p>
                </div>
            </div>
            <br />
            <a className="p-10" href="https://cool.studio/" rel="noopener noreferrer" target="_blank" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div className="gradient text-clip m-10">
                    <p>Built by Cool Studio</p>
                </div>
                <img alt="Cool Studio Logo" src={'https://cool.ams3.cdn.digitaloceanspaces.com/Branding/Full%20Logotype/SVG%20%28Web%20Vector%29/cool-studio-full-colour.svg'} style={{width:150}} />
            </a>
        </footer>
    )
  }
}
