import { Link } from 'gatsby';
import React, { Component } from 'react';
import SocialDisplay from './social-display';

export default class Navigation extends Component {
  render() {
        return <nav className="container">
            <div className="pt-25">
              <SocialDisplay />
            </div>
            <div className="nav gradient text-clip pt-25">
                <div>
                    <Link href="/">joshua carroll</Link>
                </div>
                <div>
                    <Link href="/films">my films</Link>
                    <Link href="/stills">my stills</Link>
                    <Link href="/hire">hire me</Link>
                </div>
            </div>
        </nav>
  }
}
