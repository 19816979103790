import React, { Component } from 'react'
import axios from '../axios';

import SocialItem from '../container/social-item'

export default class SocialDisplay extends Component {
  constructor() {
    super();

    this.state = {
      socials: []
    }
  }
  componentDidMount() {
    this.getAllItems()
  }
  getAllItems = () => {
    axios.get('/socials').then(e => {
      this.setState({
        socials: e.data
      });
    })
  }
  render() {
    return (
      <div className="d-grid grid-gap grid-center grid-cols-min-min-min">
        {
          this.state.socials.map(e => {
            return <SocialItem icon={e.icon} link={e.link} />
          })
        }
      </div>
    )
  }
}
